<template>
	<div>
		<div>
			<el-form :inline="true" ref="queryForm" :model="queryForm">
				<el-form-item>
					<el-input v-model="queryForm.title" placeholder="按标题查询"></el-input>
				</el-form-item>
				<!-- <el-form-item style="width: 200px">
					<treeselect v-model="queryForm.channelId" :multiple="false" placeholder="请选择栏目"
						:options="treeData" />
				</el-form-item> -->
				<el-form-item>
					<el-button icon="el-icon-search" @click="list" type="danger">查询</el-button>
					<el-button icon="el-icon-plus" @click="add" type="primary">新增</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData">
			<el-table-column prop="name" label="父级栏目" min-width="200">
				<template slot-scope="scope">
					<span v-if="scope.row.channel_id!=0">
						{{scope.row.name}}
					</span>
				</template>
			</el-table-column>
			<el-table-column prop="title" label="软件成果标题" min-width="300" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<router-link style="color: blue;" target="_blank" :to='"/detail/"+scope.row.id'>
						{{scope.row.title}}
					</router-link>
				</template>
			</el-table-column>
			<el-table-column prop="publish" label="取得时间" min-width="200"></el-table-column>
			<el-table-column align="center" label="操作" min-width="250">
				<template slot-scope="scope">
					<el-link @click="edit(scope.row)" :underline="false" type="primary" class="el-icon-edit-outline">编辑</el-link>
					<span style="display: inline-block;width: 20px;"></span>
					<el-link @click="del(scope.row)" :underline="false" type="danger" class="el-icon-delete">删除</el-link>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination background layout="total,prev, pager, next,jumper" @current-change="handleCurrentChange"
			:current-page="pageNo" :total="total">
		</el-pagination>

	</div>
</template>

<script>
	import {del,query} from "@/api/software";
	import token from "@/token.js";

	export default {
		name: "Index",
		components: {
		},
		data() {
			return {
				queryForm: {
					title: '',
					channelId: null,
				},
				tableData: [],
				treeData: [],
				total: 0,
				pageNo: 1,
				defForm: {
					id:0,
					title: '',
					channel_id: null,
					description: '',
					soft_image: '',
					system_image: '',
					publish: '',
					content: '',
					updateUserID: token.getUser().id,
					updateUserName: token.getUser().manager_username
				},
			}
		},
		mounted() {
			this.list()
		},
		methods: {
			//改变当前页
			handleCurrentChange(val) {
				this.pageNo = val;
				this.list()
			},
			//查询栏目列表
			list() {
				let param = {
					title: this.queryForm.title,
					channelId: 6,
					page: this.pageNo,
					pageSize: 10,
				}
				query(param).then(data => {
					this.tableData = data.data.list
					this.total = data.data.total
					// console.log("列表",this.tableData)
				}).catch(error => {
					this.$message.error(error)
				})
			},
			add() {
				this.$router.push('/admin/business/software_add')
			},
			edit(row) {
				this.$router.push({
					path: '/admin/business/software_edit',
					query: {
						article: row
					}
				})
			},
			del(row) {
				this.defForm.id=row.id,
				this.defForm.title=row.title,
				this.defForm.channel_id=row.channel_id,
				this.defForm.description=row.description,
				this.defForm.soft_image=row.soft_image,
				this.defForm.system_image=row.system_image,
				this.defForm.publish=row.publish,
				this.defForm.content=row.content==null ? '': row.content,
			
				this.$confirm('确定要删除吗？', "提示", {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					del(this.defForm).then(data => {
						let totalPage = Math.ceil((this.total - 1) / 10); //15 是每页15条
						let pageNo = this.pageNo > totalPage ? totalPage : this.pageNo;
						this.pageNo = pageNo < 1 ? 1 : pageNo; //this.pageNo 当前页数
						this.list()
						this.$message.success("软件成果删除成功!")
					}).catch(error => {
						this.$message.error(error)
					})
				}).catch(() => {
			
				})
			},
		}
	}
</script>

<style scoped>

</style>
