import axiosInstance from "./axiosInstance";

export function create(param) {
  return axiosInstance.post('software/addArticle',param)
}

export function update(param) {
  return axiosInstance.post('software/editArticle',param)
}

export function del(param) {
	return axiosInstance.post('software/deleteArticle', param)
}

export function query(param) {
  return axiosInstance.post('software/queryArticleList',param)
}

export function upload(formData) {
	return axiosInstance.post('file/obsUploadFile', formData)
}